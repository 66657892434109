import React, { Fragment, useCallback, useMemo, useState, useEffect } from "react";
import { formatDateWithTimezone } from "utils/formatter";
import ImagePreviewModal from "components/ImagePreviewModal";
import { classNames } from "utils/misc";

const MessageHistoryForDialog = ({ messages, page, perPage, totalCount, onNextPage, loading, invertOrder = false, slideOverRef, eventTimezone }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [imagePreviewName, setImagePreviewName] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");

  useEffect(() => {
    if (slideOverRef.current) {
      slideOverRef.current.scrollTo({
        top: slideOverRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, []);

  const sortedMessages = useMemo(() => {
    if (!invertOrder) return messages;

    return messages.reduce((acc, message) => {
      if (!acc.some(m => m.id === message.id)) {
          acc.push(message);
      }
      return acc;
    }, []).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  }, [messages, invertOrder]);

  const scrollToTop = () => {
    if (slideOverRef.current) {
      slideOverRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  const onPreviewOpen = useCallback((attachment)=> {
    setImagePreviewName(attachment.filename);
    setImagePreviewUrl(attachment.fileUrl);
    setPreviewOpen(true);
  }, []);
  const onPreviewClose = useCallback(()=> setPreviewOpen(false), []);

  const canGoToNextPage = totalCount > perPage * page;

  const handleNextPage = async () => {
    await onNextPage();

    scrollToTop();
  }

  const formatMessageBody = (bodyText) => {
    if (!bodyText) return '';

    const urlRegex = /(?:https:\/\/|http:\/\/|www\.)[^\s<]+/g;

    return bodyText.replace(urlRegex, (match) => {
      return `<a href="${match}" target="_blank" class="text-blue-500 underline">${match}</a>`;
    });
  }

  return (
    <Fragment>
      <ImagePreviewModal
        name={imagePreviewName}
        url={imagePreviewUrl}
        open={previewOpen}
        onClose={onPreviewClose} 
      />
      <h2 className="border-b border-gray-200 pt-2 pb-4 px-1 text-lg font-medium leading-6 text-gray-900 w-auto">Messages</h2>
      <ul className="sm:rounded-lg divide-y bg-white divide-gray-200 border-box sm:border-x sm:border-b overflow-y-auto" ref={slideOverRef}>
        { canGoToNextPage && 
          <li className="py-5 text-center font-semibold cursor-pointer" onClick={handleNextPage}>Show earlier</li>
        }
        { !loading && sortedMessages.length == 0 &&
          <li className="py-5 text-center">No messages.</li>
        }
        {sortedMessages.map((message) => (
          <li className={`message-card ${message.highlight ? "animate-highlight" : ""}`} key={message.id}>
            <div className="px-4 pt-4 pb-2 sm:px-6">
              <div className="flex space-x-3">
                <span className="text-sm flex-1 font-semibold text-gray-900">
                  {message.fromName}
                </span>
                <p className="text-sm text-gray-500">
                  {formatDateWithTimezone(message.createdAt, eventTimezone)}
                </p>
              </div>
            </div>
            <div className="px-4 pb-4 sm:p-4 sm:px-6 sm:pt-0">
              <div className="flex flex-wrap">
                { (message.attachments.concat(message.templateAttachments)).map((attachment) => (
                  <div key={attachment.id} className="mr-2 mb-2">
                    <img
                      className="max-w-[200px] max-h-[200px] hover:cursor-pointer"
                      src={attachment.fileUrl}
                      alt={attachment.filename}
                      onClick={() => onPreviewOpen(attachment)}
                    />
                  </div>
                ))}
              </div>
              <div className="sm:flex">
                <div className="text-sm break-normal">
                  { message.subject &&
                    <div className="mb-6">
                      <span dangerouslySetInnerHTML={{__html: message.subject}} />
                    </div>
                  }
                  <span dangerouslySetInnerHTML={{__html: formatMessageBody(message.formattedBody)}} />
                </div>
              </div>
              <div className="flex justify-end">
                { message.state === "c" &&
                  <span className={classNames(message.status === "undelivered" ? 'text-red-600' : 'text-gray-600', 'text-sm')}>
                    { message.status === "undelivered" ? "not delivered" : message.status }
                  </span>
                }
              </div>
            </div>
          </li>
        ))}
      </ul>
    </Fragment>
  );
};

export default MessageHistoryForDialog;
