import React, { useCallback, useContext, useEffect, useState } from 'react'
import MainLayout from 'components/MainLayout';
import useInbox from "hooks/useInbox";
import { InboxProvider } from 'contexts/InboxContext'
import MessageHistory from "components/MessageHistory";
import Filters from "components/inbox/Filters";
import UserContext from "contexts/UserContext";
import AssignToMememberDialog from 'components/AssignToMemberDialog';
import SimpleCombo from 'components/SimpleCombo';
import TimezoneInfo from 'components/TimezoneInfo';

const REPLY_STATUSES = {
  "inbound": "Inbox",
  "sent": "Sent",
  "archived": "Archive",
  "assigned": "Assigned"
}

const Inbox = ({ eventId, replyStatus = "inbound" }) => {
  const { setEventId, currentEventName, eventTimezone } = useContext(UserContext);

  const inboxHooks = useInbox();
  const { 
    fetchMessage, 
    archiveMessage, 
    messages, 
    MESSAGES_PER_PAGE, 
    page, 
    setPage, 
    messagesTotal, 
    loading, 
    unassignMessageFromAssociate, 
    fetchInboxMessages, 
    fetchAssociates, 
    associates 
  } = inboxHooks;
  
  const [scrollPosition, setScrollPosition] = useState(0);
  const [pages, setPages] = useState([]);

  const [msgId, setMsgId] = useState(0);
  const [assocId, setAssocId] = useState(0);
  
  const [search, setSearch] = useState('');
  const [associateFilter, setAssociateFilter] = useState(null);

  const [assignToMemberDialog, setAssignToMemberDialog] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    setEventId(eventId);
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setEventId(eventId);
    setPages([{ name: currentEventName, href: `/events/${eventId}/inbox`, current: true }]);
  }, [currentEventName]);

  useEffect(() => {
    if (scrollPosition > 120) {
      setPages([
        { name: currentEventName, href: `/events/${eventId}/inbox`, current: false },
        { name: REPLY_STATUSES[replyStatus], href: `/events/${eventId}/messages/${replyStatus}`, current: true },
      ]);
    } else if (scrollPosition < 120) {
      setPages([
        { name: currentEventName, href: `/events/${eventId}/inbox`, current: false },
      ]);
    }
  }, [scrollPosition])

  useEffect(() => setEventId(eventId), [eventId, setEventId]);

  useEffect(() => {
    const getAssociates = async () => {
      await fetchAssociates();
    };

    getAssociates();
  }, [fetchAssociates]);

  const fetchMessages = useCallback(async (append = false) => {
    await fetchInboxMessages(append, associateFilter?.id, search, replyStatus);
  }, [replyStatus, search, associateFilter, fetchInboxMessages]);

  useEffect(() => {
    fetchMessages(page > 1);
  }, [page, fetchMessages]);

  useEffect(() => {
    const assocId = new URLSearchParams(window.location.search).get("associd") ?? "";
    if (associates.length && assocId) {
      setAssociateFilter(() => associates.find((item) => item.id === assocId)); 
    }
  }, [associates])

  const onPreviousPage = useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page, setPage]);

  const onNextPage = useCallback(() => {
    if (page < messagesTotal / MESSAGES_PER_PAGE) {
      setPage(page + 1);
    }
  }, [page, messagesTotal, MESSAGES_PER_PAGE, setPage]);

  const onArchive = useCallback((e, messageId, assocId) => {
    const messageCardElement = e.target.closest("li.message-card");
    archiveMessage(messageId, () => {
      messageCardElement.style.display = "none";
    });
  }, [archiveMessage]);

  const onAssignMember = (e, messageId, assocId = 0) => {
    setMsgId(messageId)
    setAssocId(assocId)
    setAssignToMemberDialog(true)
  };

  const menuActions = [
    { id: 1, label: "Archive", action: onArchive },
    { id: 2, label: "Assign to a team member", action: onAssignMember }
  ];

  const onNewMessageCallback = useCallback(async (data) => {
    // We only append the message to the list if it's in the Inbox tab
    if (replyStatus === "inbound") {
      await fetchMessage(data.id);

      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
    // Do not show the message notification
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMessage]);

  const handleSearchChange = useCallback((event) => {
    setSearch(event.target.value)
    if (page > 1) setPage(1)
  }, [page, setSearch]);

  const handleFilterChange = useCallback((value) => {       
    setAssociateFilter(value)
    if (page > 1) setPage(1)
    
    if (value) {
      window.history.pushState({}, '', window.location.href.split("?")[0] + `?associd=${value?.id}`);
    } else {
      window.history.pushState({}, '', window.location.href.split("?")[0])
    }
  }, [page, setAssociateFilter]);
   
  return (
    <InboxProvider value={inboxHooks}>
      <MainLayout pages={pages} onNewMessageCallback={onNewMessageCallback}>
        <div className="flex flex-wrap items-center justify-between">
          <h1 id="primary-heading" className="font-bold text-3xl">
            Messages
          </h1>
        </div>

        <Filters replyStatus={replyStatus || "inbound"} />

        <div className='w-full max-w-700'>
          <div className='sm:flex justify-between items-center gap-4 mt-4'>
            <div className="mt-2">
              <input
                className="w-full rounded-md border-0 bg-white py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-gray-400"
                type="text"
                name="search"
                required={true}
                placeholder={`Search ${REPLY_STATUSES[replyStatus]}`}
                value={search || ""}
                onChange={handleSearchChange}
              />
            </div>
            
            { (replyStatus === 'inbound' || replyStatus === 'assigned') && (
              <SimpleCombo 
                options={associates}
                selectedValue={associateFilter}
                handleChange={handleFilterChange}
                inputPlaceholder="Assigned To"
              />
            )}
          </div>

          <MessageHistory
            messages={messages}
            fetchMessages={fetchMessages}
            page={page}
            perPage={MESSAGES_PER_PAGE}
            totalCount={messagesTotal}
            onPreviousPage={onPreviousPage}
            onNextPage={onNextPage}
            customerLinkEnabled={true}
            loading={loading}
            menuActions={replyStatus === "archived" ? [] : menuActions} 
            replyStatus={replyStatus || "inbound"}
            unassignMessageFromAssociate={unassignMessageFromAssociate}
          />

          <p className='px-3 py-4 border-t-[1px] bt-gray-600 sticky bottom-[0px] bg-[#fafafa] z-5' colSpan={3}>
            All times in
            <TimezoneInfo eventId={eventId} eventTimezone={eventTimezone} />
          </p>
        </div>
        
        { assignToMemberDialog && 
          <AssignToMememberDialog 
            open={assignToMemberDialog} 
            setOpen={setAssignToMemberDialog} 
            messageId={msgId}
            associateId={assocId}
          /> 
        }
      </MainLayout>
    </InboxProvider>
  )
};

export default Inbox;
