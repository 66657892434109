import { useState, useCallback, useMemo, useEffect } from 'react'
import useApi from "hooks/useApi";
import { GET_ASSOCIATE_ACCOUNTS, GET_EVENT_SETTINGS, TIMEZONES } from "utils/queries";
import useRollbar from "hooks/useRollbar";
import { SWITCH_ASSOCIATE_ACCOUNT, UPDATE_ASSOCIATE, UPDATE_LOGGED_IN_ASSOCIATE_PASSWORD } from "utils/mutations";
import { formatPhone } from 'utils/formatter'

const useUser = () => {
  const { captureError } = useRollbar();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [fullName, setFullName] = useState('');
  const [associateId, setAssociateId] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [inboundMessageOptIn, setInboundMessageOptIn] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [timezone, setTimezone] = useState('');
  const [broadcastFeatureFlag, setBroadcastFeatureFlag] = useState(false);
  const [ecommerceExperienceFeatureFlag, setEcommerceExperienceFeatureFlag] = useState(false);
  const [excludeSmsFeatureFlag, setExcludeSmsFeatureFlag] = useState(false);
  const [excludeImportsFeatureFlag, setExcludeImportsFeatureFlag] = useState(false);
  const [eventCreationDisableFeatureFlag, setEventCreationDisableFeatureFlag] = useState(false);
  const [displayListAddedDateFeatureFlag, setDisplayListAddedDateFeatureFlag] = useState(false);

  const [user, setUser] = useState({});
  const [timezones, setTimezones] = useState([]);
  const [filteredTimezones, setFilteredTimezones] = useState([]);
  const [events, setEvents] = useState(null);
  const { apolloClient, query } = useApi();
  const [loading, setLoading] = useState(false);
  const [currentEventPhoneFormatted, setCurrentEventPhoneFormatted] = useState('');
  const [eventSettings, setEventSettings] = useState(null);
  const [reportCount, setReportCount] = useState(0);
  const [associateAccounts, setAssociateAccounts] = useState([]);

  const [eventTimezone, setEventTimezone] = useState('');

  const init = useCallback(user => {
    setUser(user);
    if (user?.firstName) setFirstName(user.firstName);
    if (user?.id) setAssociateId(user.id);
    if (user?.lastName) setLastName(user.lastName);
    if (user?.fullName)setFullName(user.fullName);
    if (user?.email) setEmail(user.email);
    if (user?.phone) setPhone(user.phone);

    if (user?.timezone) setTimezone(user.timezone);
    if (user?.inboundMessageOptIn) setInboundMessageOptIn(user.inboundMessageOptIn);
    if (user?.featureSet?.broadcasts) setBroadcastFeatureFlag(user.featureSet.broadcasts);
    if (user?.featureSet?.ecommerce_experience) setEcommerceExperienceFeatureFlag(user.featureSet.ecommerce_experience);
    if (user?.featureSet?.exclude_sms) setExcludeSmsFeatureFlag(user.featureSet.exclude_sms);
    if (user?.featureSet?.exclude_imports) setExcludeImportsFeatureFlag(user.featureSet.exclude_imports);
    if (user?.featureSet?.event_creation_disabled) setEventCreationDisableFeatureFlag(user.featureSet.event_creation_disabled);
    if (user?.featureSet?.display_list_added_date) setDisplayListAddedDateFeatureFlag(user.featureSet.display_list_added_date);

    if (user?.events) setEvents(user.events);
    // We set a global default eventId, which will be the first and only event present in the user's account, when
    // either the event creation or ecommerce experience feature flags are enabled
    if (user?.eventId && (user?.featureSet?.event_creation_disabled || user?.featureSet?.ecommerce_experience)) {
      setEventId(user.eventId);
    }
  }, []);
  
  useEffect(() => {
    if (!eventId) return;

    const getEventSettings = async () => {
      fetchEventSettings(eventId);
    };
    getEventSettings();
  }, [eventId]);

  const fetchAssociateAccounts = async () => {
    try {
      const resp = await query(GET_ASSOCIATE_ACCOUNTS());

      if (resp?.data?.associateAccounts) setAssociateAccounts(resp.data.associateAccounts)
    } catch (e) {
      captureError(e);
      console.log("Failed to load associate accounts", e);
    }
  };

  const fetchEventSettings = useCallback(async (id) => {
    try {
      const { data: { event } } = await query(GET_EVENT_SETTINGS(id));

      if(event.reportCount) setReportCount(event.reportCount);

      setEventTimezone(event.timezone)
      setEventSettings(event);
    } catch (e) {
      captureError(e);
      console.log("Failed to load event settings", e);
    }
  }, [captureError, query]);

  const fetchTimezones = useCallback(
    async () => {
      try {
        const res = await query(TIMEZONES);
        setTimezones(res.data.timezones);
        setFilteredTimezones(res.data.timezones);
      } catch (e) {
        captureError(e);
        console.log("Failed to fetch timezones", e);
      }
    },
    [query, captureError]
  );

  const onUpdate = async (enqueueSnackbar) => {
    if (inboundMessageOptIn && !phone) {
      enqueueSnackbar("Please add a phone number first" , {
        autoHideDuration: 3000,
        variant: "error"
      });
      return;
    }

    try {
      setLoading(true);
      await apolloClient.mutate({
        mutation: UPDATE_ASSOCIATE,
        variables: {
          firstName: firstName,
          lastName: lastName,
          mobilephone: phone,
          timeZone: timezone,
          inboundMessageOptIn: inboundMessageOptIn
        }
      });
      enqueueSnackbar("Account settings have been updated", {
        autoHideDuration: 3000,
        variant: "success",
      });
      setLoading(false);
    } catch (e) {
      enqueueSnackbar(`${e.message.replace("GraphQL error:", "")}.`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
    }
  };

  const updatePassword = async (enqueueSnackbar, successCallback, currentPassword, password, passwordConfirmation) => {
    if (password !== passwordConfirmation) {
      enqueueSnackbar("Password and confirmation mismatch" , {
        autoHideDuration: 3000,
        variant: "error"
      });
      return;
    }

    try {
      setLoading(true);
      await apolloClient.mutate({
        mutation: UPDATE_LOGGED_IN_ASSOCIATE_PASSWORD,
        variables: {
          currentPassword,
          password,
          passwordConfirmation
        }
      });
      enqueueSnackbar("Password updated!", {
        autoHideDuration: 3000,
        variant: "success",
      });
      successCallback();
      setLoading(false);
    } catch (e) {
      enqueueSnackbar(`${e.message.replace("GraphQL error:", "")}.`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
    }
  };

  const switchAssociateAccount = async (id, successCallback) => {
    try {
      await apolloClient.mutate({
        mutation: SWITCH_ASSOCIATE_ACCOUNT,
        variables: {
          accountId: parseInt(id),
        }
      });

      successCallback();
    } catch (e) {
      console.error(e);
      captureError(e);
      setLoading(false);
    }
  };

  const currentEventName = useMemo(() => {
    return events?.find((event) => event.id === eventId)?.name || "";
  }, [eventId, events]);

  const hasEventPhone = useMemo(() => {
    if (!events) return true;
    if (!eventId) return true;

    return events?.find((event) => event.id === eventId)?.phoneNumber;
  }, [eventId, events]);

  const currentEventPhone = useMemo(() => {
    if (!events || !eventId) return false;

    const phone = events.find(event => event.id === eventId)?.phoneNumber;

    if (phone) setCurrentEventPhoneFormatted(formatPhone(phone));

    return phone;
  }, [eventId, events]);

  return {
    init,
    user,
    setUser,
    setFirstName,
    firstName,
    associateId,
    setLastName,
    lastName,
    fullName,
    setEmail,
    email,
    setPhone,
    phone,
    setTimezone,
    timezone,
    setInboundMessageOptIn,
    inboundMessageOptIn,
    setEventId,
    eventId,
    currentEventName,
    hasEventPhone,
    currentEventPhone,
    broadcastFeatureFlag,
    ecommerceExperienceFeatureFlag,
    excludeSmsFeatureFlag,
    excludeImportsFeatureFlag,
    eventCreationDisableFeatureFlag,
    displayListAddedDateFeatureFlag,
    setTimezones,
    fetchTimezones,
    setFilteredTimezones,
    filteredTimezones,
    timezones,
    loading,
    onUpdate,
    updatePassword,
    currentEventPhoneFormatted,
    eventSettings,
    reportCount,
    associateAccounts,
    fetchAssociateAccounts,
    switchAssociateAccount,
    eventTimezone
  };
};

export default useUser;
